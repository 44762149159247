export const auth = {
  SWITCH_COMPANY: '[auth] switch company',
  LOGIN: '[auth] login',
  SIGNUP: '[auth] signup',
  STEPONE: '[auth] stepone',
  SETLOADING: '[auth] setLoading',
  SETCOMPANIES: '[auth] set companies',
  SETUSER: '[auth] setuser',
  SETIMAGE: '[auth] setImageUrl',
  SETERROR: '[auth] seterror',
  UPDATEUSER: '[auth] updateUser',
  SETINVITE: '[auth] set Invite',
  RESET: '[auth] reset',
  LEAVE_TEAM: '[auth] leave team',
  NO_TEAM_MODAL: '[auth] no team modal',
  RESET_ALL: 'reset all',
  LIVE_STREAM_STATUS: '[Post type] set live stream status',
  SET_NOTIFICATION_COUNT: '[Auth] set notification count',
  EDIT_AUTH_PROFILE_IMAGE: '[Edit Image] Update',
  SET_COMPANY_SUBSCRIPTION: '[auth] get company subscription',
  SET_NO_ACCESS_MODAL: '[auth] set no access modal',
}

export const company = {
  SET_COMPANY: '[company] set company',
  UPDATE_COMPANY: '[company] updateCompany',
  SET_LOADING: '[company] setLoading',
  SET_COMPANY_SUBSCRIPTION: '[company] set company subscription',
  SET_MEMBERS: '[company] set company members',
  SET_SUBSCRIPTION_PROMPT: '[company] set sub prompt',
}

export const pipelineTypes = {
  SET_LOADING: '[pipeline] setLoading',
  SET_DETAILS: '[pipeline] setDetails',
}

export const contactTypes = {
  SET_LOADING: '[contact] setLoading',
  SET_DETAILS: '[contact] setDetails',
}

export const forgotpass = {
  SETEMAIL: '[forgotpass] setemail',
  SETCODE: '[forgotpass] setcode',
  SETERROR: '[forgotpass] seterror',
  SETLOADING: '[forgotpass] setLoading',
  RESET: '[forgotpass] reset',
  RESET_ALL: 'reset all',
}

export const config = {
  TOGGLE_SIDEBAR: '[config] toggleSidebar',
  RESET: '[config] reset',
  RESET_ALL: 'reset all',
}

export const communityResourceTypes = {
  SET_CONSTANTS: '',
  SET_LOADING: '[community-resource] set loading',
  SET_RESOURCES: '[community-resource] set resources',
}

export const award = {
  RESET: '[award] reset',
  SETLOADING: '[award] setLoading',
  SET: '[award] set',
  SET_SELECTED: '[award] setSelectedAward',
  SET_PARAMS: '[award] setAwardParams',
  OPEN_AWARD_MODAL: '[award] openAwardModal',
  SET_TABLE_COLUMNS: '[award] setTableColumns',
  SET_ANALYTICS: '[award] set award analytics',
}

export const opportunity = {
  RESET: '[opportunity] reset',
  RESET_ALL: 'reset all',
  SETLOADING: '[opportunity] setLoading',
  SET: '[opportunity] set',
  SET_DUE_OPP: '[opportunity] set due opportunities',
  SET_SELECTED: '[opportunity] setSelectedOpportunities',
  OPEN_ADD_TO_BIDBOARD: '[opportunity] openAddToBidBoard',
  OPEN_OPPORTUNITY_MODAL: '[opportunity] openOpportunityModal',
  SET_ACTIVE_ORG: '[opportunity] set active organizations',
  SET_TEAMS: '[opportunity] setTeams',
  SET_MEMBERS: '[opportunity] setMembers',
  SET_TABLE_COLUMNS: '[opportunity] setTableColumns',
  SET_TOP_OPPORTUNITIES: '[opportunity] set top opportunities',
  OPEN_FIND_CONTRACTOR_MODAL: '[opportunity] open find contractor modal',
}

export const trackedOpportunity = {
  RESET: '[tracked_opportunity] reset',
  SET: '[tracked_opportunity] set',
  TRACK: '[tracked_opportunity] Track opportunity',
  UNTRACK: '[tracked_opportunity] Untrack opportunity',
  SETLOADING: '[tracked_opportunity] setLoading',
  SET_CURRENT_TAB: '[tracked_opportunity] set current tab',
  SET_TABLE_COLUMNS: '[tracked_opportunity] setTableColumns',
  SET_OPPORTUNITIES: '[tracked_opportunity] set opportunities',
  OPEN_OPPORTUNITY_MODAL: '[tracked_opportunity] openOpportunityModal',
  SET_SELECTED: '[tracked_opportunity] setSelectedOpportunities',
}

export const saved = {
  SET: '[tracked_opportunity] set',
  RESET: '[tracked_opportunity] reset',
  SET_AWARDS: '[saved] set awards',
  SAVE: '[saved] Save module item',
  UNSAVE: '[saved] Unsave module item',
  SETLOADING: '[saved] setLoading',
  SET_TABLE_COLUMNS: '[saved] setTableColumns',
}

export const team = {
  RESET: '[team] reset',
  SETLOADING: '[team] setLoading',
  SETSTATE: '[team] setState',
  REMOVE: '[team] remove',
  REMOVE_INVITE: '[team] removeInvite',
  ADD_INVITE: '[team] addInvite',
  RESET_ALL: 'reset all',
}

export const bid = {
  RESET: '[bid] reset',
  SETLOADING: '[bid] setLoading',
  SET_BIDS: '[bid] set list view bids',
  SET_MY_BIDS: '[bid] set my bids',
  SET_PARAMS: '[bid] setParams',
  SET_LIST_VIEW_PARAMS: '[bid] set list view params',
  SET_BID_INFO: '[bid] setBidInfo',
  EDIT_BID_INFO: '[bid] editBidInfo',
  SET_EDIT_MEMBER_DROPDOWN: '[bid] setEditMemberDropDown',
  SET_EDIT_TEAM_DROPDOWN: '[bid] setEditTeamDropDown',
  UPDATE_ASSIGNED_TEAMS: '[bid] update Assigned Team',
  UPDATE_ASSIGNED_MEMBER: '[bid] update Assigned Members',
  SET_PROPOSAL_DOC: '[bid] set proposal doc',
  UPDATE_STATE: '[bid] update state',
  SET_LINK_TABLE_PARAMS: '[bid] set link table params',
  SET_LINKS: '[bid] Set links',
  SET_ATTACHMENTS: '[bid] set attachments',
  SET_TABULAR_VIEW: '[bid] set tabular view',
  SET_BOARD_VIEW: '[bid] set board view',
  SET_TABULAR_VIEW_PAGINATION: '[bid] set tabular view pagination',
  SET_BID_DECISION_MATRIX_MODAL_NEXT_SWIMLANE:
    '[bid] set bid decision matrix open',
  SET_KANBAN_VIEW: '[bid] set kanban view',
  UPDATE_KANBAN_LANE: '[bid] update lane of bid',
  FETCH_KANBAN_SWIMLANE_DATA: '[bid] fetch more in kanban swimlane',
  SET_VIEW: '[bid] set view',
  RESET_ALL: 'reset all',
  SET_BID_MEMBERS: '[bid] set bid members',
}

export const postType = {
  SET_POSTS: '[Post type] Set posts',
  TOGGLE_LIKE: '[Post type] toggle like',
  TOGGLE_LIKE_COMMENT: '[Post type] toggle like comment',
  COMMENT_POST: '[Post type] comment post',
  TOGGLE_FOLLOWER: '[Post type] toggle follower',
  DELETE_COMMENT: '[Post type] delete comment',
  EDIT_COMMENT: '[Post type] edit comment',
  ALLOW_COMMENT: '[Post type] allow comment',
  DELETE_POST: '[Post type] delete post',
  SET_COMMENT: '[Post type] set comment',
  SET_LIKES: '[Post type] set likes',
  SET_POST_TYPES: '[Post type] set post type',
  APPEND_POST: '[Post type] append post',
  EDIT_POST: '[Post type] edit post',
  SET_FILTERS: '[Post type] set filters',
  TOGGLE_POLL_RESULT: '[Post type] toggle poll result',
  RESET_VOTES: '[Post type] reset votes',
  SET_VOTES: '[Post type] set votes',
  RESET_POSTS: '[Post type] reset posts',
  SET_MODAL: '[Post type] set modal',
  SET_COMMENTS: '[Post type] set comments',
  SET_APPEND: '[Post type] set append',
  SEARCH_HASHTAGS: '[SEARCH_HASHTAGS] suggestions',
  GET_URL_METADATA: '[GET_URL_METADATA] metadata',
  PUSH_SET_LIKE_POST: '[Post type] push set like post',
  PUSH_SET_LIKE_COMMENT: '[Post type] push set like comment',
  PUSH_SET_POLL_RESULT: '[Post type] push set poll result',
  SET_GROUPS: '[Post type] set groups',
  SET_PREVIEW_LINK: '[SET_PREVIEW_LINK] set is preview link',
  LIVE_STREAM_END: '[Post type] set live stream end',
}

export const tablefilter = {
  SET_FILTER_VALUE: '[tablefilter] set filter value',
  CLEAR_FILTER: '[tablefilter] clear filter',
  SET_SEARCH_VALUE: '[tablefilter] set table search',
  RESET_ALL: 'reset all',
  SET_PARAMS: '[tablefilter] set params',
  SET_FILTERS: '[tablefilter] set multiple filters',
}
export const history = {
  SETLOADING: '[history] setLoading',
  RESET: '[history] reset',
  SET_HISTORY: '[history] set history',
  SET_HISTORY_PARAMS: '[history] set history params',
  RESET_ALL: 'reset all',
}

export const comments = {
  SETLOADING: '[comments] setLoading',
  RESET: '[comments] reset',
  SET_COMMENTS: '[comments] set comments',
  SET_COMMENTS_PARAMS: '[comments] set comments params',
  ADD_COMMENT: '[comments] add comment',
  DELETE_COMMENT: '[comments] delete comment',
  DELETE_COMMENT_ATTACHMENT: '[comments] delete comment attachment',
  UPDATE_COMMENT_DATA: '[comments] update comment data',
  EDIT_COMMENT: '[comments] edit comment',
  ADD_REACTION: '[comments] add reaction',
  REMOVE_REACTION: '[comments] remove reaction',
  RESET_ALL: 'reset all',
}

export const teamComments = {
  SETLOADING: '[teamComments] setLoading',
  RESET: '[teamComments] reset',
  SET_COMMENTS: '[teamComments] set comments',
  SET_COMMENTS_PARAMS: '[teamComments] set comments params',
  ADD_COMMENT: '[teamComments] add comment',
  DELETE_COMMENT: '[teamComments] delete comment',
  EDIT_COMMENT: '[teamComments] edit comment',
  DELETE_COMMENT_ATTACHMENT: '[teamComments] delete comment attachment',
  ADD_COMMENT_ATTACHMENT: '[teamComments] add comment attachmnent',
  ADD_REACTION: '[teamComments] add reaction',
  REMOVE_REACTION: '[teamComments] remove reaction',
  RESET_ALL: 'reset all',
}

export const teamingPortal = {
  SETLOADING: '[teamingPortal] setLoading',
  SET: '[teamingPortal] set payload',
  RESET: '[teamingPortal] reset',
  CREATE_TEAM: '[teamingPortal] create',
  SET_ALL_TEAMS: '[teamingPortal] set all team',
  SET_MY_TEAMS: '[teamingPortal] set my team',
  SET_ALL_TEAM_PAGINATION: '[teamingPortal] set all team pagination',
  SET_MY_TEAMS_PAGINATION: '[teamingPortal] set my team pagination',
  SET_SEARCH: '[teamingPortal] set search value',
  EDIT_TEAM_INFO: '[teamingPortal] edit info',
  EDIT_TEAM_ORG: '[teamingPortal] edit org',
  PIN_ALL_TEAM: '[teamingPortal] pin all team',
  PIN_MY_TEAM: '[teamingPortal] my team',
  TEAM_DROPDOWN: '[teamingPortal] set team dropdown',
  DELETE_TEAM: '[teamingPortal] delete team',
  SET_DELETE_TEAM_VISIBLE: '[teamingPortal] delete team modal',
  SET_TEAM_DETAIL: '[teamingPortal] set team detail',
  SET_TEAM_DOCUMENTS: '[teamingPortal] set documents',
  ADD_TEAM_DOCUMENT: '[teamingPortal] add document',
  DELETE_DOCUMENT: '[teamingPortal] delete Document',
  SET_DOC_PARAMS: '[teamingPortal] set docs table params',
  RESET_ALL: 'reset all',
}

export const archived = {
  SETLOADING: '[archived] setLoading',
  SET_OPPORTUNITIES: '[archived] set opportunities',
  SET_OPPORTUNITY_PAGINATION: '[archived] set opportunity pagination',

  SET_CONTACTS: '[archived] set contacts',
  SET_CONTACTS_PAGINATION: '[archived] set contacts pagination',

  SET_GLOBAL_CONFIG: '[archived] set global config',
  SET_GLOBAL_CONFIG_CONTACT: '[archived] set global config contact',
  UNARCHIVE_OPPORTUNITY: '[archived] unarchive opportunity',
  UNARCHIVE_CONTACT: '[archived] unarchive contact',
  SET_SELECTED_BID: '[archived] set selected bid',
  SET_SELECTED_CONTACT: '[archived] set selected contact',
  RESET_ALL: 'reset all',
}

export const resources = {
  SETLOADING: '[resources] setLoading',
  ADD_RESOURCE: '[resources] add',
  OPEN_EDIT_MODAL: '[resources] open add edit modal',
  EDIT_RESOURCE: '[resources] edit',
  DELETE_RESOURCE: '[resources] delete',
  SET_RESOURCE: '[resources] set',
  RESET: '[resources] reset',
  RESET_ALL: 'reset all',
}

export const directoryFilter = {
  RESET: '[directoryFilter] reset',
  RESET_ALL: 'reset all',
  SETLOADING: '[directoryFilter] setLoading',
  RESET_FILTERS: '[directoryFilter] reset filter',
  SET_DROPDOWN_OPTIONS: '[directoryFilter] set dropdown options',
  SET_FILTERS: '[directoryFilter] set filters',
  SET_TABLE_PARAMS: '[directoryFilter] set table params',
  APPLY_SAVED_FILTER: '[directoryFilter] apply saved filter',
}

export const teamMemberDropdown = {
  RESET_ALL: 'reset all',
  RESET: '[teamMemberDropdown] reset',
  SETLOADING: '[teamMemberDropdown] setLoading',
  SET_TEAMS: '[teamMemberDropdown] setTeams',
  SET_MEMBERS: '[teamMemberDropdown] setMembers',
}

export const saveSearch = {
  RESET_ALL: 'reset all',
  RESET: '[saveSearch] reset',
  SETLOADING: '[saveSearch] setLoading',
  SET_SEARCH: '[saveSearch] set saved search',
  SAVE_SEARCH: '[saveSearch] save search',
  SET_PARAMS: '[saveSearch] set params',
  DELETE_SEARCH: '[saveSearch] delete search',
  TOGGLE_MODAL: '[saveSearch] toogle saved search modal',
}

export const directory = {
  RESET: '[directory] reset',
  RESET_ALL: 'reset all',
  SETLOADING: '[directory] setLoading',
  SET_SELECTED: '[directory] set selected contractor',
  SET_DIRECTORIES: '[directory] set directories',
  ADD_TO_CRM_MODAL: '[directory] add to crm modal',
  SET_CONTACT_INFO: '[directory] set contact info ',
}

export const crm = {
  RESET_ALL: 'reset all',
  RESET: '[crm] reset',
  SETLOADING: '[crm] setLoading',
  SET_GLOBAL_CONFIG: '[crm] set global config',

  SET_TABULAR_VIEW_PAGINATION: '[crm] set tabular view pagination',
  SET_TABULAR_VIEW: '[crm] set tabular view',

  SET_LIST_VIEW_PAGINATION: '[crm] set list view pagination',
  SET_LIST_VIEW: '[crm] set list view',

  SET_BOARD_VIEW: '[crm] set board view',
  UPDATE_KANBAN_LANE: '[crm] update lane in board view',

  EDIT_CONTACT_INFO: '[crm] edit contact',

  SET_VIEW: '[crm] set view',
}

export const crmInfo = {
  RESET_ALL: 'reset all',
  RESET: '[crmInfo] reset',
  SETLOADING: '[crmInfo] setLoading',

  SET_ACTIVE_TASKS: '[crmInfo] set active tasks',

  SET_CRM_INFO: '[crmInfo] set crm info',
  SET_CRM_COMPANY: '[crmInfo] set crm company',

  SET_NAICS_CODES: '[crmInfo] set naics codes',

  SET_TEAM_DROPDOWN: '[crmInfo] set team dropdown',

  SET_MEMBER_DROPDOWN: '[crmInfo] set member dropdown',
  SET_PAST_PERFORMANCE: '[crmInfo] set past performance',

  SET_CRM_HISTORY: '[crmInfo] set crm history',
  SET_CRM_TASKS: '[crmInfo] set crm tasks',
  SET_CRM_NOTES: '[crmInfo] set crm notes',
  SET_CRM_ACTIVTY: '[crmInfo] set crm activity',
  UPDATE_CRM_NOTE: '[crmInfo] update crm note',
  UPDATE_CRM_TASK: '[crmInfo] update crm task',
  DELETE_CRM_NOTE: '[crmInfo] delete crm note',
  DELETE_CRM_TASK: '[crmInfo] delete crm task',
  ADD_CRM_NOTES: '[crmInfo] add crm notes',
  SET_ACTIVITY_FILTERS: '[crmInfo] set activity filters',
}

export const notification = {
  SET_NOTIFICATION: '[notification] set notification',
  MARK_AS_READ: '[notification] mark as read',
  SET_NOTIFICATION_COUNT: '[notification] set notification count',
  REMOVE_NOTIFICATION: '[notification] remove notification',
  SET_BELL_COUNT: '[notification] set bell count',
}

export const myMessages = {
  SET_MESSAGE_COUNT: '[My messages] set message count',
}

export const networkType = {
  SET_FOLLOWERS: '[Set Followers] Action',
  SET_FOLLOWING: '[Set Following] Action',
  SET_SUGGESTION: '[Set Suggestion] Action',
  ADD_FOLLOWER: '[Add Follower] Add',
  REMOVE_FOLLOWER: '[Remove Follower] Remove',
  SET_FILTER: '[Set Filter] Action',
}

export const userProfileTypes = {
  GET_USER_PROFILE: '[Get User Profile] Action',
  ADD_FOLLOWER: '[Add Follower] Add User Profile',
  REMOVE_FOLLOWER: '[Remove Follower] Remove User Profile',
}
export const support = {
  OPEN_MODAL: '[support] open modal',
  SET_LOADING: '[support] setLoading',
}

export const globalSearch = {
  SET_FILTER: '[Global Search] set filter',
  SET_TAB: '[Global Search] set selected tab',
  SET_SEARCH_DROP_DOWN_USER: '[Global Search] Set search drop down users',
  SET_SEARCH_DROP_DOWN_RECENT_SEARCH:
    '[Global Search] Set search drop down recent search',
  CLEAR_SEARCH_DROP_DOWN_RECENT_SEARCH:
    '[Global Search] Clear search drop down recent search',
  SET_PEOPLE: '[Global Search] Set people',
  TOGGLE_FOLLOWER: '[Global Search] Toggle Follower',
  SET_SPECIALITY: '[Global Search] Set speciality',
  SET_COMPANY_TYPE: '[Global Search] Set company type',
  SET_MUTUAL_CONNECTION: '[Global Search] Set mutual connection',
  RESET_FILTER: '[Global Search] reset filter',
  SET_POST_TYPES: '[Global Search] set post type',
}

export const communityType = {
  SET_TAB: '[community] Set Tab',
  SET_MODAL: '[community] Set Modal',
  SET_MEMBERS: '[community] Set Members',
  SET_LOADING: '[community] Set Loading',
  SET_PER_PAGE: '[community] Set Per Page',
  RESET_MEMBERS: '[community] Reset Members',
  JOIN_COMMUNITY: '[community] Join Community',
  LIST_COMMUNITY: '[community] Fetch Communitites',
  SET_CURRENT_PAGE: '[community] Set Current Page',
  SET_INVITE_USERS: '[community] Set Invite Users',
  RESET_INVITE_USERS: '[community] Reset Invite Users',
  COMMUNITY_DETAIL: '[community] Fetch Community Detail',
  SET_SEARCH_TEXT: '[community] Set Search Community Text',
  RESET_COMMUNITY_DETAILS: '[community] Reset Community Details',
}

export const eventTypes = {
  SET_MODAL: '[Event type] Set Modal',
  SET_USERS: '[Event type] Set Users',
  SET_EVENTS: '[Event type] Set Events',
  EDIT_EVENT: '[Event type] Edit Event',
  RESET_USERS: '[Event type] Reset Users',
  SET_FILTERS: '[Event type] Set Filters',
  LEAVE_EVENT: '[Event type] Leave Event',
  REMOVE_USER: '[Event type] Remove Users',
  CANCEL_EVENT: '[Event type] Cancel Event',
  DELETE_EVENT: '[Event type] Delete Event',
  RESET_EVENTS: '[Event type] Reset Events',
  SET_ATTENDEE: '[Event type] Set Attendee',
  INVITATION_SENT: '[Event type] Invitation Sent',
  TOGGLE_FOLLOWER: '[Event type] Toggle follower',
  SET_INVITE_USERS: '[Event type] Set Invite Users',
  TOGGLE_ATTENDING: '[Event type] Toggle Attending',
  TOGGLE_INTRESTED: '[Event type] Toggle Intrested',
  RESET_INVITE_USERS: '[Event type] Reset Invite Users',
}

export const plans = {
  SET_PLANS: '[plans] set plans',
  SET_PLAN: '[plans] set plan',
  SET_LOADING: '[plans] set loading',
  SET_ADDON_CHECKOUT_MODAL: '[plans] set addon checkout modal',
}

export const setAside = {
  GET_SET_ASIDES : 'Get All Set Asides',
}