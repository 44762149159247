import {
  cancellableApiRequest,
  get,
  getErrorMessage,
  post,
  put,
  uploadToPresignedUrl,
} from 'api'
import { compressImage } from 'helpers/common'
import {
  loginErrorInvalidEmail,
  loginErrorInvalidPassword,
} from 'helpers/const'
import { firebaseAuth, fireBase } from 'helpers/firebase'
import { message } from 'helpers/message'
import { history, routes } from 'routes/config'
import { auth } from 'store/types'
import { getCompanySubscription } from './company'

export const signup = (params, forOtp) => async (dispatch) => {
  try {
    if (forOtp && !params.otp && params.otp.toString().length < 6) {
      dispatch({
        type: auth.SETERROR,
        payload: { otpMessage: 'Please enter OTP' },
      })
      return
    }
    dispatch({
      type: auth.SETLOADING,
      payload: { signup: true },
    })
    const { data, status } = await post({
      subUrl: '/user/signup',
      data: params,
    })
    message.success(data.message)
    if (status === 206) {
      delete params.captchaToken
      dispatch({
        type: auth.STEPONE,
        payload: params,
      })
      history.replace(routes.verifyemailaddress.path)
      return false
    } else {
      dispatch({
        type: auth.SETERROR,
        payload: { otpMessage: '' },
      })
      // dispatch({
      //   type: auth.SIGNUP,
      //   payload: data?.data,
      // })
      return true
    }
  } catch (error) {
    const msg = getErrorMessage(error)
    if (params.otp) {
      dispatch({
        type: auth.SETERROR,
        payload: { otpMessage: msg },
      })
    }
    message.error(msg)
    throw error
  } finally {
    dispatch({
      type: auth.SETLOADING,
      payload: { signup: false },
    })
  }
}
const call = cancellableApiRequest()

export const getCompanies =
  ({ page, search, ...otherParams }) =>
  async (dispatch) => {
    try {
      const perPage = 20
      dispatch({
        type: auth.SETLOADING,
        payload: { getCompanies: true },
      })

      const { data } = await call(get, {
        subUrl: '/company/search',
        params: {
          page,
          perPage,
          str: search,
          ...otherParams,
        },
      })
      dispatch({
        type: auth.SETCOMPANIES,
        payload: {
          companies: data?.data?.companies,
          count: data?.data?.count,
          page,
          perPage,
        },
      })
    } catch (error) {
      const msg = getErrorMessage(error)
      message.error(msg)
    } finally {
      dispatch({
        type: auth.SETLOADING,
        payload: { getCompanies: false },
      })
    }
  }

//Not Using Below Server API for Login
export const login = (params) => async (dispatch) => {
  try {
    dispatch({
      type: auth.SETLOADING,
      payload: { login: true },
    })
    const { data } = await post({
      subUrl: '/user/login',
      data: params,
    })
    message.success(data.message)
    dispatch({
      type: auth.LOGIN,
      payload: data?.data,
    })
    history.push(routes.index.path)
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: auth.SETLOADING,
      payload: { login: false },
    })
  }
}

export const assignOrgToUser = (data) => async (dispatch) => {
  try {
    await post({
      subUrl: '/user/assign-organization',
      data,
    })
    await validateUser()(dispatch)
  } catch (error) {
    if (error?.isAxiosError) {
      const msg = getErrorMessage(error)
      return message.error(msg)
    }
    return message.error(error?.message)
  }
}

export const addAndAssignOrg = (data) => async (dispatch) => {
  try {
    await post({
      subUrl: '/user/add-and-assign-organization',
      data,
    })
    await validateUser()(dispatch)
  } catch (error) {
    if (error?.isAxiosError) {
      const msg = getErrorMessage(error)
      return message.error(msg)
    }
    return message.error(error?.message)
  }
}

export const verifyEmailOtp = (otp) => async (dispatch) => {
  try {
    dispatch({
      type: auth.SETLOADING,
      payload: { verifyOtp: true },
    })
    const { data } = await post({
      subUrl: '/user/verify-otp',
      data: { otp },
    })
    message.success(data.message)
    dispatch({
      type: auth.SETUSER,
      payload: { user: data.data },
    })
    history.push(routes.index.path)
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: auth.SETLOADING,
      payload: { verifyOtp: false },
    })
  }
}

export const resendVerifyEmail = (email) => async (dispatch) => {
  try {
    dispatch({
      type: auth.SETLOADING,
      payload: { resendOtp: true },
    })
    const { data } = await post({
      subUrl: '/user/send-verify-email',
      data: { email },
    })
    message.success(data.message)
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: auth.SETLOADING,
      payload: { resendOtp: false },
    })
  }
}

export const switchCompany = (userOrganizationId) => async (dispatch, getState) => {
  try {
    const authState = getState().auth
    dispatch({
      type: auth.SETLOADING,
      payload: { switchCompany: true },
    })
    const { data } = await post({
      subUrl: '/user/switch-company',
      data: {
        userOrganizationId
      }
    })
    // dispatch({
    //   type: auth.SWITCH_COMPANY,
    //   payload: { loading: {...authState.loading, switchCompany:false}, user: {...authState.user, ...data.data } },
    // })
    window.location.reload()
    
    // history.push(routes.index.path)
  } catch (err) {
    message.error("Unable to change company at the moment")
  } finally {
    dispatch({
      type: auth.SETLOADING,
      payload: { switchCompany: false },
    })
  }
}

export const firebaseLogin =
  ({ email, password }, redirectTo) =>
  async (dispatch) => {
    try {
      dispatch({
        type: auth.SETLOADING,
        payload: { login: true },
      })
      let { user } = await firebaseAuth.signInWithEmailAndPassword(
        email,
        password
      )
      user = user.toJSON()
      const accessToken = user.stsTokenManager.accessToken
      await post({
        subUrl: '/auth/login',
        apiVersion: 'v2',
        data: {
          accessToken,
        },
        withCredentials: true,
      })
      const { data: userData } = await get({
        subUrl: '/auth/user',
        apiVersion: 'v2',
        withCredentials: true,
      })

      await getCompanySubscription()(dispatch)

      dispatch({
        type: auth.LOGIN,
        payload: { user: userData },
      })
      
      if (redirectTo) {
        return window.location.href = redirectTo
      }
      // if (!data?.data?.isActive) {
      //   return // do not allow login if account is inactive
      // }
      history.push(routes.index.path)
    } catch (error) {
      if (error?.code === loginErrorInvalidEmail.code) {
        message.error(loginErrorInvalidEmail.message)
      } else if (error?.code === loginErrorInvalidPassword.code) {
        message.error(loginErrorInvalidPassword.message)
      } else if (error?.isAxiosError) {
        const msg = getErrorMessage(error)
        message.error(msg)
      } else {
        message.error(error?.message)
      }
    } finally {
      dispatch({
        type: auth.SETLOADING,
        payload: { login: false },
      })
    }
  }

export const firebaseCommunityLogin =
  ({ email, password, communityId }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: auth.SETLOADING,
        payload: { login: true },
      })
      let { user } = await firebaseAuth.signInWithEmailAndPassword(
        email,
        password
      )
      user = user.toJSON()
      const accessToken = user.stsTokenManager.accessToken
      const { data } = await get({
        subUrl: '/user',
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      dispatch({
        type: auth.LOGIN,
        payload: { ...user.stsTokenManager, user: data.data },
      })
      history.push(routes.communityDetailPage.path_string(communityId))
    } catch (error) {
      if (error?.code === loginErrorInvalidEmail.code) {
        message.error(loginErrorInvalidEmail.message)
      } else if (error?.code === loginErrorInvalidPassword.code) {
        message.error(loginErrorInvalidPassword.message)
      } else if (error?.isAxiosError) {
        const msg = getErrorMessage(error)
        message.error(msg)
      } else {
        message.error(error?.message)
      }
    } finally {
      dispatch({
        type: auth.SETLOADING,
        payload: { login: false },
      })
    }
  }

export const validateUser =
  () =>
  async (dispatch) => {
    try {
      let sessionToken = null;
      dispatch({
        type: auth.SETLOADING,
        payload: { user: true },
      })
      
      const { data: sessionData } = await post({
        subUrl: '/auth/validate-session',
        apiVersion: 'v2',
        withCredentials: true,
      })
      sessionToken = sessionData.token

      await firebaseAuth.signInWithCustomToken(sessionToken);
      const { data: userData } = await get({
        subUrl: '/auth/user',
        apiVersion: 'v2',
        withCredentials: true,
      })

      await getCompanySubscription()(dispatch)

      // if (!userData.isActive && userData.companyId) {
      //   switch (userData.activationStatus) {
      //     case 'pending-approval': {
      //       triggerNoAccessModal({
      //         visible: true,
      //         title: 'No Access',
      //         message:
      //           'Your account is going through an approval process with the Company Admin. Try again later.',
      //       })(dispatch)
      //       break
      //     }
      //     case 'deactivated': {
      //       triggerNoAccessModal({
      //         visible: true,
      //         title: 'No Access',
      //         message: 'Your account has been deactivated.',
      //       })(dispatch)
      //       break
      //     }
      //     default:
      //   }
      //   return logout()(dispatch)
      // }
      dispatch({
        type: auth.LOGIN,
        payload: {
          user: userData,
        },
      })
    } catch (error) {
      // const msg = getErrorMessage(error)
      // message.error(msg)
      throw error;
    } finally {
      dispatch({
        type: auth.SETLOADING,
        payload: { user: false },
      })
    }
  }

export const logout = () => async (dispatch) => {
  await post({
    subUrl: '/auth/sign-out',
    apiVersion: 'v2',
    withCredentials: true,
  })
  await firebaseAuth.signOut()
  dispatch({
    type: auth.RESET,
  })
  dispatch({
    type: auth.RESET_ALL,
  })
}

export const updatePassword =
  ({ oldPassword, newPassword, userId, email }) =>
  async (dispatch) => {
    try {
      if (!newPassword || !newPassword.length) {
        message.error('New password is required')
        return
      }
      dispatch({
        type: auth.SETLOADING,
        payload: { updatingUserPassword: false },
      })
      const cred = fireBase.auth.EmailAuthProvider.credential(
        email,
        oldPassword
      )
      await firebaseAuth.currentUser.reauthenticateWithCredential(cred)
      const checkForNewpass = fireBase.auth.EmailAuthProvider.credential(
        email,
        newPassword
      )
      try {
        const data =
          await firebaseAuth.currentUser.reauthenticateWithCredential(
            checkForNewpass
          )
        if (data.user) {
          message.error('New password should not be same as old password')
          return
        }
      } catch (error) {
        //New password not same as old password  continue
      }
      await firebaseAuth.currentUser.updatePassword(newPassword)
      await post({
        subUrl: '/user/on-password-update',
        data: { userId, password: newPassword },
      })
      message.success('Password updated successfully.')
    } catch (error) {
      let msg
      if (error.isAxiosError) {
        msg = getErrorMessage(error)
      } else {
        msg = error.message
      }
      message.error(msg)
    } finally {
      dispatch({
        type: auth.SETLOADING,
        payload: { updatingUserPassword: false },
      })
    }
  }

export const updateUser =
  ({
    firstname,
    lastname,
    email,
    oldPassword,
    newPassword,
    jobTitle,
    contactNumber,
    userImage,
    userId,
  }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: auth.SETLOADING,
        payload: { updateUser: true },
      })
      let data
      if (firstname || lastname || jobTitle || contactNumber || userImage) {
        ;({ data } = await put({
          subUrl: '/user',
          data: { firstname, lastname, jobTitle, contactNumber },
        }))
        dispatch({
          type: auth.UPDATEUSER,
          payload: { user: data.data },
        })
      }
      data?.message && message.success(data?.message)
      return true
    } catch (error) {
      let msg
      if (error.isAxiosError) {
        msg = getErrorMessage(error)
      } else {
        msg = error.message
      }
      message.error(msg)
    } finally {
      dispatch({
        type: auth.SETLOADING,
        payload: { updateUser: false },
      })
    }
  }

export const updateProfilePicture = (file) => async (dispatch) => {
  try {
    dispatch({
      type: auth.SETLOADING,
      payload: { uploadImage: true },
    })
    if (file === null) {
      const { data: updatedUser } = await put({
        subUrl: '/user/',
        data: { userImage: '' },
      })
      dispatch({
        type: auth.SETIMAGE,
        payload: updatedUser?.data?.userImage,
      })
    } else {
      const { data } = await get({
        subUrl: '/user/get-user-img-presigned-url',
        params: { fileName: file.name },
      })
      // const smallImage = await compressImage(file) image compression disabled
      const { url, key } = data.data
      await uploadToPresignedUrl(url, file)
      const { data: updatedUser } = await put({
        subUrl: '/user/',
        data: { userImage: key },
      })
      dispatch({
        type: auth.SETIMAGE,
        payload: updatedUser?.data?.userImage,
      })
    }
  } catch (error) {
    message.error(error?.message)
    message.error(error?.response?.message)
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: auth.SETLOADING,
      payload: { uploadImage: false },
    })
  }
}

export const becomeMember = (params) => async (dispatch) => {
  try {
    dispatch({
      type: auth.SETLOADING,
      payload: { becomeMember: true },
    })
    await post({ subUrl: '/team/become-member', data: params })
    const { data } = await get({ subUrl: '/user' })
    dispatch({
      type: auth.UPDATEUSER,
      payload: { user: data.data },
    })
    return true
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: auth.SETLOADING,
      payload: { becomeMember: false },
    })
  }
}

export const removeError = () => (dispatch) => {
  dispatch({
    type: auth.SETERROR,
    payload: { otpMessage: '' },
  })
}

export const getInvite = (params) => async (dispatch) => {
  try {
    dispatch({
      type: auth.SETLOADING,
      payload: { getInvite: true },
    })
    const { data } = await get({ subUrl: '/team/invite', params })
    dispatch({
      type: auth.SETINVITE,
      payload: data.data,
    })
    return data.data
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
    return false
  } finally {
    dispatch({
      type: auth.SETLOADING,
      payload: { getInvite: false },
    })
  }
}

export const acceptInvite = (params) => async (dispatch) => {
  try {
    dispatch({
      type: auth.SETLOADING,
      payload: { signup: true },
    })
    const { data, status } = await post({
      subUrl: '/team/accept',
      data: params,
    })
    message.success(data.message)
    if (status === 206) {
      dispatch({
        type: auth.STEPONE,
        payload: params,
      })
      history.replace(
        `${routes.verifyemailaddress.path}?invite=${params.inviteCode}`
      )
      return false
    } else {
      dispatch({
        type: auth.SETERROR,
        payload: { otpMessage: '' },
      })
      return true
    }
  } catch (error) {
    const msg = getErrorMessage(error)
    if (params.otp) {
      dispatch({
        type: auth.SETERROR,
        payload: { otpMessage: msg },
      })
    } else {
      message.error(msg)
    }
  } finally {
    dispatch({
      type: auth.SETLOADING,
      payload: { signup: false },
    })
  }
}

export const setNoTeamModal = (params) => (dispatch) => {
  dispatch({
    type: auth.NO_TEAM_MODAL,
    payload: params,
  })
}
export const leaveTeam = (params) => (dispatch) => {
  dispatch({
    type: auth.LEAVE_TEAM,
  })
}

export const updateLiveStreamStatus = (status) => async (dispatch) => {
  try {
    dispatch({
      type: auth.LIVE_STREAM_STATUS,
      payload: status,
    })
    return true
  } catch (e) {
    throw e
  }
}

export const getNotificiationCount = () => async (dispatch) => {
  try {
    const data = (
      await get({
        subUrl: '/user',
      })
    ).data.data.notification_count
    dispatch({
      type: auth.SET_NOTIFICATION_COUNT,
      payload: {
        notification_count: data,
      },
    })
  } catch (e) {
    throw e
  }
}

export const getUser = () => async (dispatch, getState) => {
  try {
    const { accessToken, refreshToken } = getState().auth
    return validateUser({
      accessToken,
      refreshToken,
    })(dispatch)
  } catch (error) {
    throw error
  }
}

export const triggerNoAccessModal = (data) => (dispatch) => {
  dispatch({
    type: auth.SET_NO_ACCESS_MODAL,
    payload: data,
  })
}
