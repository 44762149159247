import { isMoreEntries } from 'helpers/common'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { REDUCERKEY } from '../../helpers/const'
import { auth } from '../types'

const initialState = {
  accessToken: null,
  refreshToken: null,
  user: {
    id: 'anonymous',
    firstname: 'Anonymous',
    lastname: 'User',
    role: 'anonymous'
  },
  companies: null,
  fetchMoreCompanies: true,
  tempUserData: null,
  otpMessage: '',
  inviteInfo: null,
  isBlockedForNotMemberModal: false,
  noAccessModal: {
    visible: false,
    message: '',
    title: 'No Access',
  },
  loading: {
    switchCompany: false,
    user: false,
    login: false,
    signup: false,
    getCompanies: false,
    verifyOtp: false,
    resendOtp: false,
    updateUser: false,
    uploadImage: false,
  },
  live_stream_status: false,
}

export default persistReducer(
  {
    key: REDUCERKEY,
    whitelist: [
      'accessToken',
      'refreshToken',
      // process.env.NODE_ENV === 'development' ? 'user' : '',
    ],
    storage,
  },
  /**
   * @param {initialState} state
   * @param {{type:string; payload:any}} action
   * @returns {initialState}
   */
  (state = initialState, action) => {
    switch (action.type) {
      case auth.SWITCH_COMPANY: {
        return { ...state, ...action.payload }
      }
      case auth.LOGIN: {
        return { ...state, ...action.payload }
      }
      case auth.STEPONE: {
        return { ...state, tempUserData: action.payload }
      }
      case auth.SIGNUP: {
        const { accessToken, refreshToken, user } = action.payload
        return {
          ...state,
          accessToken,
          refreshToken,
          user,
          tempUserData: undefined,
        }
      }
      case auth.SETUSER: {
        const { user } = action.payload
        return { ...state, user }
      }
      case auth.SETIMAGE: {
        const url = action.payload
        return { ...state, user: { ...state.user, userImage: url } }
      }
      case auth.UPDATEUSER: {
        const { user } = action.payload
        return { ...state, user: { ...state.user, ...user } }
      }
      case auth.SETLOADING: {
        return {
          ...state,
          loading: { ...state.loading, ...action.payload },
        }
      }
      case auth.SETCOMPANIES: {
        const { companies, page, perPage, count } = action.payload
        return {
          ...state,
          companies:
            page === 1 ? companies : [...state.companies, ...companies],
          fetchMoreCompanies: isMoreEntries({ count, page, perPage }),
        }
      }

      case auth.SETERROR: {
        const { otpMessage } = action.payload
        return { ...state, otpMessage }
      }
      case auth.SETINVITE: {
        return { ...state, inviteInfo: action.payload }
      }

      case auth.NO_TEAM_MODAL: {
        return { ...state, isBlockedForNotMemberModal: action.payload }
      }

      case auth.RESET: {
        return {
          ...initialState,
          noAccessModal: state.noAccessModal, // do not reset this
        }
      }
      case auth.LEAVE_TEAM: {
        return {
          ...state,
          user: {
            ...state.user,
            companyId: null,
          },
        }
      }
      case auth.SET_NOTIFICATION_COUNT: {
        return {
          ...state,
          user: {
            ...state.user,
            notification_count: action.payload.notification_count,
          },
        }
      }
      case auth.LIVE_STREAM_STATUS: {
        return { ...state, live_stream_status: action.payload }
      }
      case auth.SET_NO_ACCESS_MODAL: {
        return {
          ...state,
          noAccessModal: action.payload,
        }
      }

      default: {
        return state
      }
    }
  }
)
